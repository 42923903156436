/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import PropTypes from 'prop-types'

import { bannerSmall, useBreakpoints } from '@hmn/coolinarika-web-core/hooks'

import { Column, Row } from '@hmn/coolinarika-web-ui/components/Layout'
import { FeedItemController } from '@hmn/coolinarika-web-ui/controllers'

const GRID_VARIANT_1x1 = '1x1'
const GRID_VARIANT_1x2 = '1x2'
const GRID_VARIANT_2x1 = '2x1'
const GRID_VARIANT_2x2 = '2x2'
const GRID_VARIANT_FULL = 'fullWidth'

const RECIPE = 'recipe'
const ARTICLE = 'article'
const COOL_CHEF = 'cool-chef'
const PROMOTION = 'promotion'
const BANNER = 'banner'
const TASTE_PROFILE = 'taste_profile'
const USER_PROFILE = 'user_profile'
const NEWSLETTER = 'newsletter'
const GIVEAWAY = 'giveaway'
const INGREDIENT = 'ingredient'
const FOOD_TYPE = 'food'
const REGISTRATION = 'registration'
const BIG_MOFO = 'big_mofo'
const INSPIRATION = 'taxon'
const IMAGE = 'image'
const MENU = 'menu'

const DISTRIBUTION_VALUES = [0.3, 0.3, 0.3, 0.1]

// for card size mappings see link - https://docs.google.com/spreadsheets/d/1w8ZEMNPlrTj6dskyZ-j-hqSVqX3WMMfH_32hZS6gmsE/edit#gid=636175413
const DISTRIBUTION_MAPPING = [GRID_VARIANT_1x1, GRID_VARIANT_1x2, GRID_VARIANT_2x1, GRID_VARIANT_2x2, GRID_VARIANT_FULL]
const CARD_GRID_VARIANTS = {
    [IMAGE]: [true, false, false, false, false],
    [RECIPE]: [true, false, false, true, false],
    [ARTICLE]: [true, false, false, false, false],
    [GIVEAWAY]: [false, true, true, true, false],
    [INGREDIENT]: [true, false, false, false, false],
    [COOL_CHEF]: [false, true, true, false, false],
    [PROMOTION]: {
        [BANNER]: [true, true, false, false, false],
        [ARTICLE]: [false, true, true, true, false],
        [TASTE_PROFILE]: [false, false, true, false, false],
        [USER_PROFILE]: [false, true, true, false, true],
        [NEWSLETTER]: [false, true, true, false, true],
        [REGISTRATION]: [false, true, true, false, true]
    },
    [FOOD_TYPE]: [false, true, true, true, false],
    [BIG_MOFO]: [true, true, false, true, false],
    [INSPIRATION]: [false, true, false, false, false],
    [MENU]: [false, true, false, false, false]
}

const debugFilterMissing = feedItems =>
    Array.isArray(feedItems) &&
    feedItems?.length &&
    feedItems.filter(feedItem => {
        const { type } = feedItem

        if (type === PROMOTION) {
            const { promotion } = feedItem

            if (promotion === USER_PROFILE || promotion === REGISTRATION) {
                return false
            }
        }

        if (type === FOOD_TYPE || type === GIVEAWAY) {
            return false
        }

        return true
    })

const fittingCheckBool = (firstRow, secondRow, preferedVariant) => {
    const referentIndex = firstRow.findIndex(element => element === false)

    switch (preferedVariant) {
        case GRID_VARIANT_1x1: {
            return true
        }
        case GRID_VARIANT_1x2: {
            return true
        }
        case GRID_VARIANT_2x1: {
            if (firstRow[referentIndex] === false && firstRow[referentIndex + 1] === false) {
                return true
            }
            return false
        }
        case GRID_VARIANT_2x2: {
            if (
                firstRow[referentIndex] === false &&
                firstRow[referentIndex + 1] === false &&
                secondRow[referentIndex] === false &&
                secondRow[referentIndex + 1] === false
            ) {
                return true
            }
            return false
        }
        case GRID_VARIANT_FULL: {
            return firstRow.every(element => element === false)
        }
        default: {
            return false
        }
    }
}

const getDistributionMap = ({ max, min }) => {
    const classIncrement = max - min
    let currentValue = 0.0

    return DISTRIBUTION_VALUES.reduce((current, prev) => {
        current.push(currentValue)

        currentValue += classIncrement * prev

        return current
    }, [])
}

const getItemPopularity = contentItem =>
    parseFloat(
        Math.abs(
            (contentItem?.metrics?.interestingness * contentItem?.metrics?.seasonality) /
                contentItem?.metrics?.popularity
        ) || 0.0
    )

const getAverageContentItemPopularityReducer = (contentItems = []) =>
    (contentItems?.length &&
        contentItems.reduce((totalSumAcc, contentItem) => totalSumAcc + getItemPopularity(contentItem), 0.0) /
            contentItems.length) ||
    50

const getCurrentDataSetMetrics = (feedItems = []) => {
    const max =
        (feedItems?.length && feedItems.reduce((current, prev) => Math.max(current, getItemPopularity(prev)), 0.0)) ||
        100

    const min =
        (feedItems?.length && feedItems.reduce((current, prev) => Math.min(current, getItemPopularity(prev)), 0.0)) || 0

    return {
        average: getAverageContentItemPopularityReducer(feedItems),
        count: feedItems.length,
        max,
        min
    }
}

const setNewGridVariant = (firstRowBool, secondRowBool, gridVariant) => {
    const referentIndex = firstRowBool.findIndex(element => element === false)

    switch (gridVariant) {
        case GRID_VARIANT_1x1: {
            firstRowBool[referentIndex] = true
            return [firstRowBool, secondRowBool]
        }
        case GRID_VARIANT_1x2: {
            firstRowBool[referentIndex] = true
            secondRowBool[referentIndex] = true

            return [firstRowBool, secondRowBool]
        }
        case GRID_VARIANT_2x1: {
            firstRowBool[referentIndex] = true
            firstRowBool[referentIndex + 1] = true

            return [firstRowBool, secondRowBool]
        }
        case GRID_VARIANT_2x2: {
            firstRowBool[referentIndex] = true
            firstRowBool[referentIndex + 1] = true
            secondRowBool[referentIndex] = true
            secondRowBool[referentIndex + 1] = true

            return [firstRowBool, secondRowBool]
        }
        case GRID_VARIANT_FULL: {
            firstRowBool.fill(true)

            return [firstRowBool, secondRowBool]
        }
        default: {
            return [firstRowBool, secondRowBool]
        }
    }
}

const GridLayoutController = ({ items, groupIndex, noRow, onClickEventTracking, onViewEventTracking }) => {
    const uid = useUIDSeed()
    const [bannerSmallUp] = useBreakpoints(bannerSmall)

    // const debugDistinctItemTypes = feedItems => {
    //     const distinctItemTypes = new Set()
    //     feedItems.forEach(({ extended_attributes: { type } }) => {
    //         distinctItemTypes.add(type)
    //     })

    //     return distinctItemTypes
    // }

    const mapRowWithArray = useCallback(
        (contentItems, index) => {
            const feedItemControllerItems = contentItems.map((tempItem, tempIndex) => (
                <FeedItemController
                    key={uid(`${tempItem?.id}${tempIndex}` || `${groupIndex}-${index}-${tempIndex}`)}
                    index={tempIndex}
                    groupIndex={groupIndex}
                    item={tempItem}
                    type={tempItem?.type}
                    gridVariant={tempItem.gridVariant}
                    onClickEventTracking={onClickEventTracking}
                    onViewEventTracking={onViewEventTracking}
                />
            ))

            return noRow ? (
                <Row variant="contentFeed.row" key={uid(groupIndex)}>
                    <Column variant="grid.12">{feedItemControllerItems}</Column>
                </Row>
            ) : (
                <Column variant="grid.12" key={uid(groupIndex)}>
                    {feedItemControllerItems}
                </Column>
            )
        },
        [items, groupIndex, noRow]
    )

    // const mapRowWithSingleItem = useCallback(
    //     (item, index) =>
    //         noRow ? (
    //             <Row variant="promotion.banner" key={uid(index + 1)}>
    //                 <FeedItemController
    //                     index={index}
    //                     groupIndex={groupIndex}
    //                     item={item}
    //                     type={item?.type}
    //                     gridVariant={item.gridVariant}
    //                 />
    //             </Row>
    //         ) : (
    //             <FeedItemController
    //                 key={uid(index + 1)}
    //                 index={index}
    //                 groupIndex={groupIndex}
    //                 item={item}
    //                 type={item?.type}
    //                 gridVariant={item.gridVariant}
    //             />
    //         ),
    //     [items, groupIndex, noRow]
    // )

    const generateLayout = useCallback(
        contentItems => {
            let firstRowBool = [false, false, false]
            let secondRowBool = [false, false, false]

            const wildCardArticles = []
            const currentDataSetMetrics = getCurrentDataSetMetrics(contentItems)
            const distributionMap = getDistributionMap(currentDataSetMetrics)
            const unfittedBanners = []
            const initContentItemsLength = contentItems.length
            for (let itemIndex = 0; itemIndex < contentItems.length; itemIndex += 1) {
                const contentItem = contentItems[itemIndex]

                if (Object.keys(contentItem).length === 0) {
                    // eslint-disable-next-line no-continue
                    continue
                }

                const { type: realType, metrics, promotion, desktop, extended_attributes, taxons } = contentItem
                let isGiveaway = false

                if (realType === ARTICLE) {
                    isGiveaway = taxons?.['coolinarika-flags']?.some(({ slug }) => slug === 'flag-giveaway')
                }

                const { native, promoted } = extended_attributes || {}

                let type = realType

                if (native || promoted || isGiveaway) {
                    type = BIG_MOFO
                }

                const { interestingness, seasonality, popularity: initialPopularity } = metrics || {}

                const popularity = Math.abs((interestingness * seasonality) / initialPopularity) || 0

                if (type === ARTICLE && wildCardArticles.length < 10) {
                    wildCardArticles.push(contentItem)
                }

                let preferedSizeIndex = DISTRIBUTION_MAPPING.length - 1

                if (type !== PROMOTION && type !== BIG_MOFO) {
                    preferedSizeIndex = distributionMap.reduce((curr, prev, index) => {
                        if (CARD_GRID_VARIANTS[type][index] && parseFloat(prev) < parseFloat(popularity)) {
                            return index
                        }

                        return curr
                    }, CARD_GRID_VARIANTS[type]?.indexOf(true)) // if none fits, return smallest one
                }

                for (preferedSizeIndex; preferedSizeIndex >= 0; preferedSizeIndex -= 1) {
                    let gridVariant

                    if (promotion && promotion !== BANNER && promotion !== 'registration') {
                        if (CARD_GRID_VARIANTS[type][promotion][preferedSizeIndex]) {
                            gridVariant = DISTRIBUTION_MAPPING[preferedSizeIndex]
                        } else {
                            // eslint-disable-next-line no-continue
                            continue
                        }
                    } else if (CARD_GRID_VARIANTS[type][preferedSizeIndex]) {
                        gridVariant = DISTRIBUTION_MAPPING[preferedSizeIndex]
                    } else if (promotion !== BANNER) {
                        // eslint-disable-next-line no-continue
                        continue
                    }

                    if (type !== PROMOTION) {
                        if (fittingCheckBool(firstRowBool, secondRowBool, gridVariant)) {
                            ;[firstRowBool, secondRowBool] = setNewGridVariant(firstRowBool, secondRowBool, gridVariant)
                            contentItem.gridVariant = gridVariant
                            break
                        }
                    } else if (promotion === BANNER) {
                        let bannerVariant

                        if (!bannerSmallUp) {
                            if (fittingCheckBool(firstRowBool, secondRowBool, '2x1')) {
                                bannerVariant = '2x1'
                            }
                        } else if (fittingCheckBool(firstRowBool, secondRowBool, desktop?.gridVariant)) {
                            bannerVariant = desktop?.gridVariant
                        }

                        if (bannerVariant) {
                            ;[firstRowBool, secondRowBool] = setNewGridVariant(
                                firstRowBool,
                                secondRowBool,
                                bannerVariant
                            )
                            contentItem.gridVariant = bannerVariant
                            break
                        }
                    } else if (fittingCheckBool(firstRowBool, secondRowBool, gridVariant)) {
                        ;[firstRowBool, secondRowBool] = setNewGridVariant(firstRowBool, secondRowBool, gridVariant)
                        contentItem.gridVariant = gridVariant
                        break
                    }
                }

                if (contentItem.gridVariant) {
                    if (firstRowBool.every(el => el === true)) {
                        if (secondRowBool.every(el => el === true)) {
                            firstRowBool.fill(false)
                            secondRowBool.fill(false)
                        } else {
                            firstRowBool = [...secondRowBool]

                            secondRowBool.fill(false)
                        }
                    }
                } else {
                    const newItem = JSON.parse(JSON.stringify(contentItem))
                    if (itemIndex + 2 < initContentItemsLength - 1) {
                        contentItems.splice(itemIndex + 2, 0, newItem)
                    } else {
                        unfittedBanners.push(newItem)
                    }
                }
            }

            const wildcardsFlag = wildCardArticles.length - 1
            const secureFlag = 20
            let wildCardArticlesIndex = 0
            let secureFlagCount = 0

            while (
                (!firstRowBool.every(el => el === false) || unfittedBanners.length !== 0) &&
                wildCardArticlesIndex < wildcardsFlag &&
                secureFlagCount <= secureFlag
            ) {
                secureFlagCount += 1
                if (unfittedBanners.length !== 0) {
                    if (fittingCheckBool(firstRowBool, secondRowBool, GRID_VARIANT_2x1)) {
                        const newArticle = JSON.parse(JSON.stringify(unfittedBanners.pop()))

                        ;[firstRowBool, secondRowBool] = setNewGridVariant(
                            firstRowBool,
                            secondRowBool,
                            GRID_VARIANT_2x1
                        )

                        newArticle.gridVariant = GRID_VARIANT_2x1

                        contentItems.push(newArticle)
                    } else {
                        const newArticle = JSON.parse(JSON.stringify(wildCardArticles[wildCardArticlesIndex]))

                        ;[firstRowBool, secondRowBool] = setNewGridVariant(
                            firstRowBool,
                            secondRowBool,
                            GRID_VARIANT_1x1
                        )

                        newArticle.gridVariant = GRID_VARIANT_1x1
                        wildCardArticlesIndex += 1

                        contentItems.push(newArticle)
                    }
                } else {
                    let preferedSizeIndex = 3

                    if (secondRowBool.every(el => el === false)) {
                        const cnt = firstRowBool.filter(el => el === false).length

                        if (cnt === 1) {
                            preferedSizeIndex = 0
                        } else {
                            preferedSizeIndex = 2
                            if (firstRowBool[1] === true) {
                                preferedSizeIndex = 0
                            }
                        }
                    }

                    for (preferedSizeIndex; preferedSizeIndex >= 0; preferedSizeIndex -= 1) {
                        const gridVariant = DISTRIBUTION_MAPPING[preferedSizeIndex]

                        if (fittingCheckBool(firstRowBool, secondRowBool, gridVariant)) {
                            ;[firstRowBool, secondRowBool] = setNewGridVariant(firstRowBool, secondRowBool, gridVariant)

                            if (wildCardArticles?.length) {
                                const newArticle = JSON.parse(JSON.stringify(wildCardArticles[wildCardArticlesIndex]))
                                newArticle.gridVariant = gridVariant
                                wildCardArticlesIndex += 1
                                contentItems.push(newArticle)
                            }

                            break
                        }
                    }
                }

                if (firstRowBool.every(el => el === true)) {
                    if (secondRowBool.every(el => el === true)) {
                        firstRowBool.fill(false)
                        secondRowBool.fill(false)
                    } else {
                        firstRowBool = [...secondRowBool]

                        secondRowBool.fill(false)
                    }
                }
            }
        },
        [bannerSmallUp]
    )

    const contentFeedLayoutGenerator = feedItems => {
        // debug only, see if any new data coming from API
        // console.log(debugDistinctItemTypes(feedItems))
        // Currently unavailable card components and data for food-type and giveaway, breaking layout if included
        let contentItemGroup = debugFilterMissing(feedItems) || []

        generateLayout(contentItemGroup)

        contentItemGroup =
            Array.isArray(contentItemGroup) &&
            contentItemGroup?.length &&
            contentItemGroup.filter(item => item.gridVariant !== undefined)
        const tempArray = []

        const rowArray =
            Array.isArray(contentItemGroup) &&
            contentItemGroup?.length &&
            contentItemGroup.reduce((current, item, index) => {
                tempArray.push(item)
                return current
            }, [])

        if (tempArray.length !== 0) {
            rowArray.push(mapRowWithArray(tempArray, contentItemGroup?.length))
        }

        return rowArray
    }

    const gridItems = useMemo(() => contentFeedLayoutGenerator(items), [bannerSmallUp, items])

    if (gridItems && gridItems?.length) {
        return gridItems
    }

    return null
}

export const ItemWithinFeedPropType = PropTypes.shape({
    author: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    created_at: PropTypes.string,
    description: PropTypes.string,
    extended_attributes: PropTypes.shape({
        type: PropTypes.string,
        intro: PropTypes.string,
        quantity: PropTypes.string,
        seasonality: PropTypes.string
    }),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    lead: PropTypes.string,
    owned_by_user_profile: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    published: PropTypes.bool,
    published_at: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    update_at: PropTypes.string
})

GridLayoutController.propTypes = {
    groupIndex: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(ItemWithinFeedPropType).isRequired,
    noRow: PropTypes.bool,
    onClickEventTracking: PropTypes.func,
    onViewEventTracking: PropTypes.func
}

GridLayoutController.defaultProps = {
    noRow: false,
    onClickEventTracking: undefined,
    onViewEventTracking: undefined
}

export default GridLayoutController

import { useRouter } from 'next/router'

const isDev = process.env.NODE_ENV !== 'production'

const webRoot = process.env.NEXT_PUBLIC_WEB_ROOT

const usePageUrl = () => {
    const { asPath } = useRouter()

    const webBase = `${isDev ? 'http' : 'https'}://${
        webRoot && typeof webRoot !== 'string' ? webRoot?.value : webRoot || 'www.coolinarika.com'
    }`

    return `${webBase}${asPath}`
}

export default usePageUrl

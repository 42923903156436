import { useMemo } from 'react'
// eslint-disable-next-line
import Head from 'next/head'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../../../ErrorBoundary'

const removeUndefinedObjectKeys = (object, ignoredKeys = []) =>
    Object.keys(object)
        .sort()
        .reduce((acc, curr) => {
            let value = object[curr]
            if (value === Object(value) && !Array.isArray(value)) {
                value = removeUndefinedObjectKeys(value)
                // if object has no other keys without ignored ones, remove the object
                if (ignoredKeys && Object.keys(value).filter(key => ignoredKeys.indexOf(key) === -1).length === 0) {
                    return acc
                }
            }
            if (!value && value !== 0) {
                // any null-ish value except 0
                return acc
            }
            acc[curr] = value
            return acc
        }, {})

const StructuredData = ({ data, dataOverwrite }) => {
    const dataWithSortedKeys = useMemo(
        () =>
            removeUndefinedObjectKeys(
                {
                    ...data,
                    ...dataOverwrite
                },
                ['@type', '@schema']
            ),
        [data, dataOverwrite]
    )

    if (!dataWithSortedKeys) {
        return null
    }

    return (
        <Head>
            <script
                type="application/ld+json"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: JSON.stringify(dataWithSortedKeys, null, 2) }}
            />
        </Head>
    )
}

StructuredData.propTypes = {
    data: PropTypes.oneOfType([() => null, PropTypes.object]),
    dataOverwrite: PropTypes.oneOfType([() => null, PropTypes.object])
}

StructuredData.defaultProps = {
    data: undefined,
    dataOverwrite: undefined
}
export default withErrorBoundary(StructuredData, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // Do something with the error, e.g. log it to Sentry or console, defaults to console
        // eslint-disable-next-line no-console
        console.error('[StructuredData]: ', error, componentStack)
    }
})

const getCardItemUrl = item => {
    switch (item.type) {
        case 'recipe':
            return `https://www.coolinarika.com/recept/${item?.slug}-${item?.id}`
        case 'article':
            return `https://www.coolinarika.com/blog/${
                item?.taxons?.['coolinarika-article-category']?.[0]?.slug || 'coolinarika'
            }/${item?.slug ? `${item?.slug}-` : ''}${item?.id}`
        case 'ingredient':
            return `https://www.coolinarika.com/namirnica/${item?.slug ? `${item?.slug}-` : ''}${item?.id}`
        default:
            return 'https://www.coolinarika.com'
    }
}

export default getCardItemUrl
